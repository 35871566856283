import React,{ useEffect, useState } from 'react';
import { Row, Col, Card, Space, Progress, Table, Button,Tag,Tooltip,BackTop,Skeleton, message, Spin } from 'antd';
import { CopyOutlined, ArrowRightOutlined, TeamOutlined, FileDoneOutlined, SearchOutlined,BankOutlined } from "@ant-design/icons";
import "../../../styles/admindashboard.css";
import Chart from "react-google-charts";
import constants from "../../../constants/constants";
import { fetchAdminApi, fetchAdminDownloadApi } from "../../../services/api";
import { useHistory } from "react-router";
import moment from "moment";
import { COOKIE,getCookie } from "../../../services/cookie";
import Loader from '../../Common/Cred/Loader';
const AdminDashboard = () => {
	const history = useHistory();
	const [totalRegistrationCount, setTotalRegistrationCount] = useState(null);
	const [totalTestCount, setTotalTestCount] = useState(0);
	const [totalPassed, setTotalPassed] = useState(0);
	const [totalFailed, setTotalFailed] = useState(0);
	const [passPercent, setPassPercent] = useState(0);
	const [failPercent, setFailPercent] = useState(0);
	const [totalCountUniversity, setTotalCountUniversity] = useState(0);
	const [tableData, setTableData] = useState([]);
	const [studentListingTableData, setStudentListingTableData] = useState([]);
	const [graphFeedBackData, setGraphFeedBackData] = useState([]);
	const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
	const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [loader, setLoader] = useState(false);
	useEffect(()=>{
		return () => {
			let userId = getCookie(COOKIE.UserId);
			if (!userId){
				history.push("/admin-login");
			}else{
				if (history.action === "POP") {
					history.push("/admin-dashboard");
				}
			}
		};
	},[history]);
	useEffect(() => {
		fetchTotalRegistrationCount();
		fetchTotalTestTakenCount();
		fetchPerformanceStatistic();
		fetchRegistrationByUniversity();
		fetchRegistrationByUniversityForTable();
		fetchFeedBackData();
		fetchStudentListingData();
		fetchStartAndEndDate();
	}, []);
	const fetchStartAndEndDate=()=>{
		var currentDate = new Date();
		var theFirst = new Date(currentDate.getFullYear(), 0, 1);
		var theLast = new Date(currentDate.getFullYear(), 11, 31);
		setStartDate(theFirst);
		setEndDate(theLast);
	};
	const fetchTotalRegistrationCount = () =>{
		const currentYear = moment().year();
		const Year = `/total-registrations?year=${currentYear}`;
		fetchAdminApi(Year, "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					let count = res.data.map(item => parseInt(item.count)).reduce((prev, curr) => prev + curr, 0);
					setTotalRegistrationCount(count);
				}else{
					setTotalRegistrationCount(null);
				}
			}
		});
	};
	const fetchTotalTestTakenCount = () =>{
		fetchAdminApi('/total-tests-taken-count', "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					let data = res.data;
					data.map((count) =>{
						let total = count.total;
						setTotalTestCount(total);
					});
				}else{
					setTotalTestCount(null);
				}
			}
		});
	};
	const fetchPerformanceStatistic =()=>{
		fetchAdminApi('/dashboard-performance-statistics', "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					let data = res.data;
					data.map((count) =>{
						let total_passed = count.total_passed;
						let total_failed = count.total_failed;
						let pass_percent = count.pass_percent;
						let fail_percent = count.fail_percent;
						setTotalPassed(total_passed);
						setTotalFailed(total_failed);
						setPassPercent(pass_percent);
						setFailPercent(fail_percent);
					});
				}else{
					setTotalPassed(null);
					setTotalFailed(null);
					setPassPercent(null);
					setFailPercent(null);
				}
			}
		});
	};
	const fetchRegistrationByUniversity = () =>{
		fetchAdminApi("/students-by-university", "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					const data = res.data;
					const totalCount = data.map(item => parseInt(item.total_count)).reduce((prev, curr) => prev + curr, 0);
					console.log("university",totalCount);
					setTotalCountUniversity(totalCount);
				} else {
					setTotalCountUniversity(null);
				}
			}
		});
	};
	const fetchRegistrationByUniversityForTable = () =>{
		fetchAdminApi("/students-by-university?limit=5", "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					setTableData(res.data);
				} else {
					setTableData([]);
				}
			}
		});
	};
	const fetchFeedBackData= () =>{
		fetchAdminApi("/feedback-chart", "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					let data = res.data;
					let gfHeader = [];
					gfHeader.push(["Feedback", "Count"]);
					data.map(obj => gfHeader.push([obj.status_name, parseInt(obj.count)]));
					console.log("gfHeader",gfHeader);
					setGraphFeedBackData(gfHeader);
				} else {
					setGraphFeedBackData([]);
				}
			}
		});
	};
	const fetchStudentListingData = () =>{
		fetchAdminApi(`/list-registered-students?limit=10`, "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					setStudentListingTableData(res.data);
				} else {
					setStudentListingTableData([]);
				}
			}
		});
	};
	const registedStudent = (record,type) =>{
		let payload = {};
		payload.sd = constants.startDateOfTheYear;
		payload.ed = constants.endDateOfTheYear;
		payload.type = type;
		payload.state_id = record.state_id;
		payload.university_id = parseInt(record.university_id);
		payload.serchCriteria = `University: ${record.university_name},${`Registrations from ${moment(payload.sd).format('MM/DD/YYYY')} to ${moment(payload.ed).format('MM/DD/YYYY')}`}`;
		history.push({
			pathname: "/registered-students",
			state: payload
		});
	};
	const allRegColumn = [
		{
			title: '',
			key: 'university',
			dataIndex: 'university_name',
			render: (_text, record) => {
				let occupation_type = 4;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative">{record.university_name}</span></a></span>;
			},
		},
		{
			title: '',
			key: 'reg',
			dataIndex: 'total_count',
			className: 'td-right',
			align:"center",
			render: (_text, record) => {
				let occupation_type = 4;
				return <span ><a onClick={() => { registedStudent(record,occupation_type); }} style={{ color: "black" }}><span className="p-relative">{record.total_count}</span></a></span>;
			},
		},
	];
	const nameEditButton =(record,object) =>{
		let name = null;
		if (object.is_active === "4"){
			name = record;
		}else{
			name = record +" "+object?.stud_lastname?.charAt(0);
		}
		return <span><Button type="link" onClick={() => 
		{
			sessionStorage.setItem("showEditSaveButton","show");
			history.push({
				pathname: "/edit-student",
				state: { 
					studentId: object.stud_id,
					dashboard: "true"
				}
			});
		}
		}>{name}</Button></span>;
	};
	const column = [
		{
			title: "Name",
			key: "stud_firstname",
			dataIndex: "stud_firstname",
			render: (record, object) => {
				if (record.length > 15) {
					return <Space>
						<Tooltip title={record}>
							{nameEditButton(record.slice(0, 15)+ "...",object)}
						</Tooltip>
					</Space>;
				} else {
					return nameEditButton(record.slice(0, 15),object);
				}
			},
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: 150
		},
		{
			title: "Username",
			key: "username",
			dataIndex: "username",
			render: (record,obj) => {
				if(obj.is_active === "4"){
					return <span></span>;
				}
				if (record.length > 15) {
					return <Space>
						<Tooltip title={record}>
							<span>{record.slice(0, 15)}...</span>
						</Tooltip>
					</Space>;
				} else {
					return <span>{record}</span>;
				}
			},
			sorter: (a, b) => a.username.localeCompare(b.username),
			width: 150
		},
		{
			title: "Country",
			key: "country",
			dataIndex: "country",
			sorter: (a, b) => a.country.localeCompare(b.country),
			align: 'center',
			width: 100
		},
		{
			title: "State",
			key: "state",
			dataIndex: "state",
			sorter: (a, b) => {
				a = a.state || '';
				b = b.state || '';
				return a.localeCompare(b);
			},
			align: 'center',
			width: 100
		},
		{
			title: "University",
			key: "university",
			dataIndex: "university",
			sorter: (a, b) => {
				a = a.university || '';
				b = b.university || '';
				return a.localeCompare(b);
			},
			width: 300,
			render:(_record,type) =>{
				if(type.new_university =="YES"){
					return <span>{type&&type.university?type.university  :""}&nbsp;&nbsp;{<BankOutlined className="icon-md" style ={{ color:"grey" }}/>}</span>;
				}else{
					return <span>{type&&type.university?type.university:""}</span>;
				}
			},
		},
		{
			title: 'Tests',
			key: 'test',
			render: (record) => {
				return (
					<Space>
						{record.test_taken&&record.test_taken!=="0"?
							<>
								<span>{record.test_taken}</span>
								<Button type="link" icon={<SearchOutlined/>} onClick = {()=>{
									history.push({
										pathname:"/test",
										state: { 
											count:record?.test_taken,
											userId:record && record.stud_id,
											name:record?.stud_firstname +" "+record?.stud_lastname?.charAt(0),
											routeBack:"/admin-dashboard"
										}
									});
								}}></Button>
							</>
							:""}
					</Space>
				);
			},
			align: 'center',
			width: 100
		},
		{
			title: "Role",
			key: "role",
			dataIndex: "role",
			sorter: (a, b) => {
				a = a.role || '';
				b = b.role || '';
				return a.localeCompare(b);
			},
			render: (_record,type) => {
				let tooltip = "";
				if(type.role == "S"){
					tooltip = constants.Role_type.student;
				}else if( type.role == "P"){
					tooltip =constants.Role_type.professional;
				}else if ( type.role =="F"){
					tooltip =constants.Role_type.faculty;
				}else if ( type.role =="O"){
					tooltip =constants.Role_type.other;
				}
				return <Tooltip title={tooltip}>
					<span>{type.role}</span>
				</Tooltip>;
			},
			width: 50,
			align: 'center',
		},
		{
			title: "Registered On",
			key: "dt_created",
			dataIndex: "dt_created",
			render: (record,records) => {
				return records && records.dt_created?<Tooltip title={records.time_created}><span>{record?moment(records.dt_created).format('MM/DD/YYYY'): ""}</span> </Tooltip>:"";
			},
			sorter: (a, b) => new Date(a.dt_created) - new Date(b.dt_created),
			width: 150,
		},
		{
			title: "Valid until",
			key: "valid_till",
			dataIndex: "valid_till",
			render: (record) => { return <span>{record? moment(record).format('MM/DD/YYYY'): ""}</span>;},
			sorter: (a, b) => new Date(a.valid_till) - new Date(b.valid_till),
			width: 150,
		},
		{
			title: 'Status',
			key: 'is_active',
			render: (record) => {
				if(record.is_active =="1"){
					return <Tag color={'blue'} >Active</Tag>;
				}else if(record.is_active =="0"){
					return <Tag color={'red'} >Inactive</Tag>;
				}else if(record.is_active =="2"){
					return <Tag color={'yellow'} >Pending</Tag>;
				}
			},
			align: 'center',
			width: 100
		},
	];
	const graphOption = {
		title: ``,
		legend: { position: 'none' },
		colors: ['#000080'],
		bar: { groupWidth: "95%" },
		hAxis: {
			title: "How did the students hear about the book?",
			slantedText: true ,
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false
			},
			minorGridlines: { 
				count: 0,
				color: 'transparent',
			},
		},
		vAxis: {
			title: "No. of Feedbacks",
			titleTextStyle: {
				fontName: "sans-serif",
				fontSize: 15,
				bold: true,
				italic: false,
			},
			format: '#'
		},
		minorGridlines: { 
			count: 0,
			color: 'transparent',
		},
	};
	//To download listing (export to excel)
	const downloadListing = (id) => {
		setLoader(true);
		fetchAdminDownloadApi(`/export-students/${id}`, "get").then(
			(res) => {
				if(res){
					let url,a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application/vnd.ms-excel" }),
						{ type: "data:attachment/xls" }
					);
					let todayDate= moment().format(constants.dateFormat);
					let filename="";
					let fileNameType="";
					if(id==="1"){
						fileNameType = constants.ActiveUsers;
					}else{
						fileNameType = constants.LatestRegistrations;
					}
					if(constants.EnviromentName!=="qa"&&constants.EnviromentName!=="staging"&&constants.EnviromentName!=="dev"){
						filename=`${fileNameType}(${todayDate}).xls`;
					}else{
						filename=constants.EnviromentName+` - ${fileNameType}(${todayDate}).xls`;
					}
					a = document.createElement("a");
					a.download = filename;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
					setLoader(false);
				}else{
					message.error("Failed to download");
					setLoader(false);
				}
			}
		);
	};
	return (
		<>
			<BackTop/>
			<div className="p-4 admin-dashboard">
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 30
				}]}
				className='m-auto'>
					<Col xs={24} sm={24} md={24} lg={6} className="slp-admin-card">
						<Card hoverable onClick = {() =>{history.push("/total-registrations");}}>
							<Row className='h-100'>
								<Col span={8} className="card-purple place-center"><TeamOutlined className="icon-lg "/></Col>
								<Col span={16} className="flex-vertical-middle ">
									<div className="px-2 m-2">
										<p className="font-16 mb-0">Total Registrations</p>
										<p className="font-14 mb-0">(This Year)</p>
										<h2 className="mb-0">{totalRegistrationCount}</h2>
									</div>
									<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2"/></div>
								</Col>
							</Row>
						</Card>        
					</Col>
					<Col xs={24} sm={24} md={24} lg={6} className="slp-admin-card">
						<Card hoverable onClick = {() =>{history.push({
							pathname:"/total-test-taken",
							state:{ type:"dashboard" }
						});}}>
							<Row className='h-100'>
								<Col span={8} className="card-yellow place-center"><FileDoneOutlined className="icon-lg "/></Col>
								<Col span={16} className="flex-vertical-middle ">
									<div className="px-2 m-2">
										<p className="font-16 mb-0">Total Tests Taken</p>
										<p className="font-14 mb-0">(This Year)</p>
										<h2 className="mb-0">{totalTestCount}</h2>
									</div>
									<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2"/></div>
								</Col>
							</Row>
						</Card>           	
					</Col>
					<Col xs={24} sm={24} md={24} lg={6} className="slp-admin-card">
						<Card hoverable onClick = {() =>{history.push("/test-list");}}>
							<Row className='h-100'>
								<Col span={8} className="card-orange place-center"><span className="icon-lg q-icon">Q</span></Col>
								<Col span={16} className="flex-vertical-middle ">
									<div className="px-2 m-2">
										<p className="font-22 mb-0">Questions</p>
									</div>
									<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2"/></div>
								</Col>
							</Row>
						</Card>          
					</Col>
					<Col xs={24} sm={24} md={24} lg={6} className="slp-admin-card">
						<Card hoverable onClick = {() =>{history.push("/flashcard-list-chapter");}}>
							<Row className='h-100'>
								<Col span={8} className="card-blue place-center"><CopyOutlined className="icon-lg"/></Col>
								<Col span={16} className="place-center">
									<div className="pr-5 m-2">
										<p className="font-22 mb-0">Flashcards</p>
									</div>
									<div className="crd-arrow"><ArrowRightOutlined className="icon-md text-mute pr-2 pb-2"/></div>
								</Col>
							</Row>
						</Card>          
					</Col>
				</Row>
				<Row gutter={[{
					xs: 0,
					sm: 0,
					md: 24,
					lg: 24
				}, {
					xs: 24,
					sm: 24,
					md: 24,
					lg: 40
				}]}>
					<Col xs={24} sm={24} md={24} lg={24} className="mt-4">
						<Card 		title={`Feedback Chart  ${startDate && endDate ? `(${moment(startDate).format(constants.dateFormat)} to ${moment(endDate).format(constants.dateFormat)})`:`(${moment(constants.startDateOfTheYear).format(constants.dateFormat)} to ${moment(constants.endDateOfTheYear).format(constants.dateFormat)})`}`}
							extra={<><Button onClick = { ()=>{
								history.push("/feedback-analysis");
							}} type="link" className='p-0'>view more {'>>'}</Button></>}>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24} className="">
									<Chart
										chartType="ColumnChart"
										width="100%"
										height="400px"
										loader={<div><p className="pt-4">Loading Chart...</p><Skeleton active/></div>}
										data={graphFeedBackData}
										options={graphOption}
									/>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} className="">
						<Card title="Progress Analysis This Year (Full Length)" extra={<><Button onClick = { ()=>{
							history.push({
								pathname:'/test-scores',
								state:{ type:"dashboard" }
							});
						}} type="link" className='p-0'>view more {'>>'}</Button></>}>
							<div className='d-flex progress-gap mb-2'>
								<span className='nowrap min-width-70'>{constants.percentagePass} - above</span>
								<Progress
									strokeColor= "#77CEBB"
									percent={passPercent}
									showInfo={false}
								/>
								<span className='nowrap prog-per'>{totalPassed} Tests ({Math.round(passPercent)}%)</span>
							</div>
							<div className='d-flex progress-gap'>
								<span className='nowrap min-width-70'>0% - 61%</span>
								<Progress
									strokeColor= "#CD3D64"
									percent={failPercent}
									showInfo={false}
								/>
								<span className='nowrap prog-per'>{totalFailed} Tests ({Math.round(failPercent)}%)</span>
							</div>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} className="">
						<Card title={<>Highest Registrations This Year (Total: {totalCountUniversity})</>} extra={<><Button onClick = { ()=>{
							history.push({ pathname:"/registration-by-university", });
						}} type="link" className='p-0'>view more {'>>'}</Button></>}>
							<Table className="admin-gap-table hig-reg-table" 
								locale={{ emptyText: 'No Records Found' }}
								dataSource={tableData} 
								columns={allRegColumn}
								Header={false}
								pagination={false}
								title={false}
								showHeader={false}
							/>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} className="">
						<Card title={<Space>
							<span>Latest Registrations</span>
							<Spin spinning={loader} size="large" indicator={<Loader/>}>
								<Space>
									<span style={{ visibility: "hidden" }}><a onClick={() => downloadListing("0")}>Listing</a></span>
									<span style={{ visibility: "hidden" }}><a onClick={() => downloadListing("1")}>Current Listing</a></span>
								</Space>
							</Spin>
						</Space>} extra={<><Button onClick = { ()=>{
							history.push({
								pathname:"/registered-students",
								state: {
									sd : startOfMonth,
									ed : endOfMonth,
									serchCriteria: `Registrations from ${moment(startOfMonth).format('MM/DD/YYYY')} to ${moment(endOfMonth).format('MM/DD/YYYY')}`
								}
							});
						}} type="link" className='p-0'>view more {'>>'}</Button></>}>
							<Table className="admin-table" 
								locale={{ emptyText: 'No Records Found' }}
								dataSource={studentListingTableData} 
								columns={column}
								Header={false}
								pagination={false}
								title={false}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default AdminDashboard;
